import React from 'react'

const InputBox = ({name, type, value, setValue}) => {
    return (
        <div>
            <label className="label">
                <span className="text-base label-text right">{name}</span>
            </label>
            <input 
                type={type} 
                placeholder={`Enter ${name}`} 
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="w-full input input-bordered input-primary border-gray-300" />
        </div>
    )
}

export default InputBox