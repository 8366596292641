//import axios from 'axios';
import request, { resetAuthTokenRequest, requestNewToken } from './resolve'

async function login(user, pass) {
    localStorage.setItem('email', user);
    localStorage.setItem('password', pass);
    return await requestNewToken()
}

async function translate(object) {
    resetAuthTokenRequest()
    var newToken = request({
        method: "post",
        url: '/tools/translate',
        data: object
    }).then((res) => {
        console.log('response for translate:--'+JSON.stringify(res))
        return res
    });

    return newToken;
}

export default {
    login, translate
}