import { Link } from "react-router-dom";
import { ThemeContext } from "./themeContext";

const Header = ({ compact }) => {

  const displayMode = compact ? "" : "border-b border-b-base-300 box-border bg-base-200";

  return <header
    class={`w-screen ${displayMode}`}
    hx-boost="true" hx-swap="innerHTML transition:true" >

    <div
      class="navbar px-6 grid grid-cols-[max-content_1fr_max-content_max-content] lg:grid-cols-[1fr_max-content_max-content] gap-x-4">
      <div class="flex-none lg:hidden">
        <label htmlFor="site-drawer" class="btn btn-square btn-ghost drawer-button">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            class="inline-block w-5 h-5 stroke-current">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16">
            </path>
          </svg>
        </label>
      </div>

      <div><Link to="/" class="font-light text-2xl" unstable_viewTransition>AI Toolbox</Link></div>

      <ThemeContext.Consumer>
        {({ setTheme }) => (
          <div class="dropdown  dropdown-end">
            <div tabindex="0" role="button" class="btn btn-ghost">
              Theme
              <svg width="12px" height="12px" class="h-2 w-2 fill-current opacity-60 inline-block"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048">
                <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path>
              </svg>
            </div>
            <ul tabindex="0" class="dropdown-content z-[1] p-2 shadow-2xl bg-base-300 rounded-box w-52">
              <li><input type="radio" name="theme-dropdown"
                class="theme-controller btn btn-sm btn-block btn-ghost justify-start" aria-label="Default"
                value="default" onClick={() => { setTheme("light") }} /></li>
              <li><input type="radio" name="theme-dropdown"
                class="theme-controller btn btn-sm btn-block btn-ghost justify-start" aria-label="Retro"
                value="retro" onClick={() => { setTheme("retro") }} />
              </li>
              <li><input type="radio" name="theme-dropdown"
                class="theme-controller btn btn-sm btn-block btn-ghost justify-start" aria-label="Cyberpunk"
                value="cyberpunk" onClick={() => { setTheme("cyberpunk") }} /></li>
              <li><input type="radio" name="theme-dropdown"
                class="theme-controller btn btn-sm btn-block btn-ghost justify-start" aria-label="Valentine"
                value="valentine" onClick={() => { setTheme("valentine") }} /></li>
              <li><input type="radio" name="theme-dropdown"
                class="theme-controller btn btn-sm btn-block btn-ghost justify-start" aria-label="Aqua"
                value="aqua" onClick={() => { setTheme("aqua") }} />
              </li>
            </ul>
          </div>
        )}
      </ThemeContext.Consumer>

      <ThemeContext.Consumer>
        {({ theme, setTheme }) => (
          <label class="cursor-pointer grid place-items-center">
            <input type="checkbox" value="light"
              class="toggle theme-controller bg-base-content row-start-1 col-start-1 col-span-2" onClick={() => { setTheme(theme === "dark" ? "light" : "dark") }} />
            <svg class="col-start-1 row-start-1 stroke-base-100 fill-base-100" xmlns="http://www.w3.org/2000/svg"
              width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round">
              <circle cx="12" cy="12" r="5" />
              <path
                d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
            </svg>
            <svg class="col-start-2 row-start-1 stroke-base-100 fill-base-100" xmlns="http://www.w3.org/2000/svg"
              width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round">
              <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
            </svg>
          </label>
        )}
      </ThemeContext.Consumer>
    </div>
  </header >
};

export default Header;
