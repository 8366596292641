import Layout from "../layout/Toolbox";

const HR = () => (
  <Layout>
    <h1 class="font-light text-2xl">HR</h1>
  </Layout>
);

export default HR;

