import Layout from "../layout/Toolbox";


const Playground = () => (
  <Layout>
    <h1 class="font-light text-2xl">Playground</h1>
  </Layout>
);

export default Playground;
