import Layout from "../layout/Toolbox";

const SEO = () => (
  <Layout>
    <h1 class="font-light text-2xl">SEO</h1>
  </Layout>
);

export default SEO;

