import { Link } from "react-router-dom";
import Layout from "../layout/Light";

const Welcome = () => (
  <Layout>
    <div class="grid content-center h-full pb-24" hx-boost="true" hx-swap="innerHTML transition:true">
      <h1 class="font-thin text-7xl text-center pb-24">Hello</h1>
      <div class="grid grid-cols-2 sm:grid-cols-3 gap-6 lg:gap-8">
        <Link class="w-32 h-32 rounded-lg border bg-base-200 p-8 shadow-xl hover:text-secondary hover:border-secondary transition-colors"
          state={{ referer: "/" }}
          to="/tools/playground" title="Playground" unstable_viewTransition>
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 50 50"
            class="w-full h-full">
            <path fill="currentColor"
              d="M7.206 31.141c2.885 0 5.219-2.399 5.219-5.368c0-2.953-2.334-5.353-5.219-5.353C4.333 20.42 2 22.82 2 25.773c0 2.968 2.333 5.368 5.206 5.368m29.23 9.216a.53.53 0 0 1 .741.117l.965 1.372a.578.578 0 0 1-.116.766l-7.08 5.287a.536.536 0 0 1-.743-.118l-.962-1.372a.575.575 0 0 1 .116-.764zm-8.003-6.817l-2.808-5.063l-1.474 1.107l2.808 5.09zm-6.551-11.827L10.962 2l-2.089.014l11.522 20.82zm10.281 10.43C32.78 31.682 34.192 31 35 31h10c1.974 0 3 1.986 3 4.004C48 37.034 46.974 38 45 38h-9l-10.836 8.502c-3.808 2.819-6.116-.278-6.116-.278l-8.483-8.729c-1.423-1.753-1.115-5.089.591-6.566l11.739-8.597c1.166-1 2.897-.843 3.885.343c.976 1.2.822 2.994-.346 3.996l-7.515 5.657l5.399 5.484z" />
          </svg>
        </Link>
        <Link to="/tools/seo"
          class="w-32 h-32 rounded-lg border bg-base-200 p-8 shadow-xl hover:text-secondary hover:border-secondary transition-colors"
          title="SEO" unstable_viewTransition>
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"
            class="w-full h-full">
            <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              color="currentColor">
              <path d="m5 20l2.413-2.413m0 0a4.444 4.444 0 1 0 6.285-6.285a4.444 4.444 0 0 0-6.285 6.285" />
              <path
                d="M3 15.188a7.4 7.4 0 0 1-1-3.726C2 7.341 5.358 4 9.5 4h5c4.142 0 7.5 3.341 7.5 7.462c0 3.25-2.087 6.014-5 7.038" />
            </g>
          </svg>
        </Link>
        <Link to="/tools/hr"
          class="w-32 h-32 rounded-lg border bg-base-200 p-8 shadow-xl hover:text-secondary hover:border-secondary transition-colors"
          title="HR" unstable_viewTransition>
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 2048 2048"
            class="w-full h-full">
            <path fill="currentColor"
              d="M1636 693q65 33 117 81t90 108t57 128t20 142h-128q0-79-30-149t-83-122t-122-82t-149-31q-79 0-149 30t-122 83t-82 122t-31 149q0 91-41 173t-115 136q65 33 117 81t90 108t57 128t20 142h-128q0-79-30-149t-83-122t-122-82t-149-31q-79 0-149 30t-122 83t-82 122t-31 149H128q0-73 20-141t57-128t89-108t118-82q-73-54-114-136t-42-173q0-79 30-149t83-122t122-82t149-31q91 0 173 41t136 115q38-75 97-134t134-97q-73-54-114-136t-42-173q0-79 30-149t83-122t122-82t149-31q79 0 149 30t122 83t82 122t31 149q0 91-41 173t-115 136m-996 715q52 0 99-20t81-55t55-81t21-100q0-52-20-99t-55-81t-82-55t-99-21q-53 0-99 20t-81 55t-55 82t-21 99q0 53 20 99t55 81t81 55t100 21m512-1024q0 53 20 99t55 81t81 55t100 21q52 0 99-20t81-55t55-81t21-100q0-52-20-99t-55-81t-82-55t-99-21q-53 0-99 20t-81 55t-55 82t-21 99" />
          </svg>
        </Link>
        <Link to="/tools/translations"
          class="w-32 h-32 rounded-lg border bg-base-200 p-8 shadow-xl hover:text-secondary hover:border-secondary transition-colors"
          title="Translations" unstable_viewTransition>
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32"
            class="w-full h-full">
            <path fill="currentColor"
              d="M4 4v18h6v6h18V10h-6V4zm2 2h14v4.563L10.562 20H6zm5 2v1H8v2h4.938c-.13 1.15-.482 2.054-1.063 2.688a4.567 4.567 0 0 1-.906-.407c-.704-.418-.97-.86-.97-1.28H8c0 1.192.734 2.182 1.72 2.844A8.487 8.487 0 0 1 8 15v2c1.772 0 3.248-.405 4.375-1.156c.524.09 1.053.156 1.625.156v-1.875c.543-.91.833-1.973.938-3.125H16V9h-3V8zm10.438 4H26v14H12v-4.563zM20 13.844l-.938 2.844l-2 6l-.062.156V24h2v-.875l.03-.125h1.94l.03.125V24h2v-1.156l-.063-.157l-2-6L20 13.845zm0 6.28l.28.876h-.56l.28-.875z" />
          </svg>
        </Link>
        <a href="#"
          class="w-32 h-32 rounded-lg border bg-base-200 p-8 shadow-xl hover:text-secondary hover:border-secondary opacity-50 hover:opacity-100 border-dashed transition-all"
          title="Add Module">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 28 28"
            class="w-full h-full">
            <path fill="currentColor"
              d="M14.5 13V3.754a.75.75 0 0 0-1.5 0V13H3.754a.75.75 0 0 0 0 1.5H13v9.253a.75.75 0 0 0 1.5 0V14.5l9.25.003a.75.75 0 0 0 0-1.5z" />
          </svg>
        </a>
      </div>
    </div>
  </Layout>
);

export default Welcome;
