import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

// import LoginPage from "./routes/Login";
import WelcomePage from "./routes/Welcome";
import PlaygroundPage from "./routes/Playground";
import SEOPage from "./routes/SEO";
import HRPage from "./routes/HR";
import TranslationsPage from "./routes/Translations";
import LoginPage from './routes/Login';

import { ThemeContext } from "./components/themeContext";

import './App.css';
import api from './webservices/API';


function App() {
  const [user, setUser] = useState({ email: "" });
  const [theme, setTheme] = useState(null);


  const Login = details => {
    loginAPI(details.email, details.password)
  }

  const loginAPI = async (email, password) => {
    const loginRes = await api.login(email, password);
    if (loginRes.error) {
      // Handle error for LOGIN HERE
    }
    else {
      const projectId = loginRes.projectId
      if (projectId !== undefined) {
        setUser({
          email: email
        })
        const userData = {
          email: email,
          projectId: projectId,
          refreshToken: loginRes.refreshToken
        }
        sessionStorage.setItem('userData', JSON.stringify(userData));
      }
    }
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/welcome",
      element: <WelcomePage />,
    },
    {
      path: "/tools",
      children: [
        {
          path: "playground",
          element: <PlaygroundPage />,
        },
        {
          path: "seo",
          element: <SEOPage />,
        },
        {
          path: "hr",
          element: <HRPage />,
        },
        {
          path: "translations",
          element: <TranslationsPage />,
        },
      ],
    },
    {
      path: "login",
      element: <LoginPage Login={Login} />
      ,
    },
  ]);


  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div data-theme={theme} className="min-h-screen relative flex flex-col justify-center min-h-screen">
        <RouterProvider router={router} />
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
