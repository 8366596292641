import axios from 'axios';
import { Buffer } from 'buffer'
//import qs from 'qs';

export async function resolve(promise) {
    const resolved = {
        data: null,
        error: null
    };

    try {
        resolved.data = await promise;
    } catch (e) {
        resolved.error = e;
    }

    return resolved;
}



const baseURL = 'https://app.chataa.ch/api/v1';
let authTokenRequest;

/**
  * @description axios instance for ajax requests
*/
//const userData = JSON.parse(sessionStorage.getItem('userData'));
var client = axios.create({
    baseURL: baseURL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
        appID: 8,
        version: "1.1.0"
        // empID: localStorage.getItem('empID'),
        // token: localStorage.getItem('refreshToken')
    }
});

/**
 * @description this method calls a requestNewToken method to issue a 
 new token to the client
*/

function getAuthToken() {
    if (!authTokenRequest) {
        authTokenRequest = requestNewToken();
        authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
    }

    return authTokenRequest;
}

/**
  * @description this method requests the server to issue a new token, 
  the server response is updated in local storage refreshToken
*/

export async function requestNewToken() {
    const token = `${localStorage.getItem('email')}:${localStorage.getItem('password')}`;
    const encodedToken = Buffer.from(token).toString('base64');
    var newToken = request({
        method: "get",
        url: '/users/login',
        headers: { 'Authorization': 'Basic '+ encodedToken }
        // data: qs.stringify({
        //     "email": localStorage.getItem('email'),
        //     "password": localStorage.getItem('password')
        // })
    }).then((res) => {
        // if (res.status == "success") {
        if (res.refreshToken) {
            localStorage.setItem('refreshToken', res.refreshToken);
            return res;
        } else {
            window.location = "/logout";
        }
    });

    return newToken;
}

export function resetAuthTokenRequest() {
    authTokenRequest = null;
}

/**
  * @description if any of the API gets 401 status code, this method 
   calls getAuthToken method to renew refreshToken
  * updates the error configuration and retries all failed requests 
  again
*/
client.interceptors.request.use(function (config) {
    const token = localStorage.getItem('refreshToken');
    if (!config.headers.Authorization) {
        if (token) {
            config.headers.Authorization = token ? `Bearer ${token}` : '';
        }
    }
    return config;
});

client.interceptors.response.use(undefined, err => {
    const error = err.response;
    // if error is 401 
    if (error.status === 401 && error.config &&
        !error.config.__isRetryRequest) {
        // request for a new token
        return getAuthToken().then(response => {
            // update the error config with new token
            error.config.__isRetryRequest = true;
            error.config.headers.token = localStorage.getItem("refreshToken");
            return client(error.config);
        });
    }
});

/**
 * @description wrapper for making ajax requests
 * @param {object} object with method,url,data etc.
*/

const request = function (options) {
    const onSuccess = function (response) {
        return response.data;
    }
    const onError = function (error) {
        console.error('Request Failed:', error.config);
        if (error.response) {
            console.error('Status:',  error.response.status);
            console.error('Data:',    error.response.data);
            console.error('Headers:', error.response.headers);
        } else {
            console.error('Error Message:', error.message);
        }
        return Promise.reject(error.response || error.message);
    }

    return client(options)
        .then(onSuccess)
        .catch(onError);
        //options
}

export default request;