import React, { useEffect, useState } from 'react';
import Layout from "../layout/Toolbox";
import api from '../webservices/API';
function Translations() {
  const [sentence, setSentence] = useState('');
  const [language, setLanguage] = useState('');
  //const [sentenceResponse, setSentenceResponse] = useState('');

  const [sentences, setSentences] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (sentence && language) {
      translateAPI(sentence, language)
    }
  }
  
  const translateAPI = async (sentence, lang) => {
    const requestObj = {
      sentence: sentence,
      lang: lang
    }
    console.log("Hello---1---" + requestObj)
    const translateResponse = await api.translate(requestObj);
    if (translateResponse.error) {
      // Handle error for LOGIN HERE
    }
    else {
      const translation = translateResponse.translation
      //setSentenceResponse(translation)
      setInputValues(oldArray => [...oldArray, sentence]);
      setSentences(oldArray => [...oldArray, translation]);
    }
  }
  return (
    <Layout>
      <div class='flex items-end w-full'>
        <form className="space-y-3 w-full" onSubmit={handleSubmit}>
          {sentences.map((item, idx) =>
            <div>
              <div class="chat chat-end">
                <div class="chat-bubble">
                  {inputValues[idx]}
                </div>
              </div>
              <div class="chat chat-start">
                <div class="chat-bubble bg-green-500">
                  {item}
                </div>
              </div>
              
            </div>
          )}
          <h1 class="font-light text-lg" >Write a sentence you want to translate:</h1>
          <div class="flex flex-row space-x-4">
            <input type="text" placeholder="Type here" class="input input-bordered w-full `" value={sentence} onChange={(e) => setSentence(e.target.value)} />
            <div class={`dropdown dropdown-top tooltip ${language ? `tooltip-close` : `tooltip-open`} `} data-tip="Select language">
              <div tabindex="0" role="button" class="btn">{language ? language : 'Language'}</div>
              <ul tabindex="0" class="p-2 shadow menu dropdown-content z-[1] menu bg-base-100 rounded-box w-52">
                <li><a onClick={(e) => setLanguage('Italian')}>Italian</a></li>
                <li><a onClick={(e) => setLanguage('German')}>German</a></li>
                <li><a onClick={(e) => setLanguage('Spanish')}>Spanish</a></li>
                <li><a onClick={(e) => setLanguage('French')}>French</a></li>
              </ul>
            </div>
            <div>
              <button class={`btn btn-outline ${sentence ? `btn-active` : `btn-disabled`}`}>Translate</button>
            </div>
          </div>
        </form>
      </div>


    </Layout>
  );
}

export default Translations;
