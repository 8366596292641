import { Link } from "react-router-dom";
import Header from "../components/Header";

const Layout = ({ children }) => (
  <div class="grid grid-rows-[max-content_1fr_max-content] grid-tem justify-items-center min-h-screen gap-6 lg:gap-12">
    <Header compact />

    <main class="max-w-2xl">
      <article class="h-full scale">
        {children}
      </article>
    </main>

    <footer class="flex gap-6 border-t border-t-neutral min-h-12">
      <Link to="/login" class="p-2 hover:border-t hover:border-t-blue-400 hover:-mt-[1px] hover:text-blue-400" unstable_viewTransition>How it
        works</Link>
      <a href="#" class="p-2 hover:border-t hover:border-t-blue-400 hover:-mt-[1px] hover:text-blue-400">Privacy
        Policy</a>
      <a href="#" class="p-2 hover:border-t hover:border-t-blue-400 hover:-mt-[1px] hover:text-blue-400">Terms of
        Service</a>
    </footer>
  </div>
);

export default Layout;
