
import React, { useState } from 'react'
import { Link } from "react-router-dom";

import Header from "../components/Header";
import InputBox from '../components/InputBox/InputBox';
import Layout from "../layout/Light";

const LoginPage = ({ Login }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Username:', email);
    console.log('Password:', password);
    // Add your login logic here
    let details = { email: email, password: password };
    
    Login(details);
  };

  return (
    <div class="grid grid-rows-[max-content_1fr_max-content] grid-tem justify-items-center min-h-screen gap-6 lg:gap-12">
      <Header compact />

      <main class="max-w-2xl">
        <article class="h-full">
          <div className='relative grid content-center h-full overflow-hidden fade-out'>
            <div class="pb-24">
              <h1 class="font-thin text-7xl text-center pb-24">Login</h1>
              <div className="w-full p-6 m-auto bg-base-200 rounded-md shadow-md lg:max-w-lg border">
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <InputBox name={'Email'} type={'text'} value={email} image={email} setValue={setEmail} />
                  <InputBox name={'Password'} type={'password'} value={password} image={email} setValue={setPassword} />
                  <a href="#" className="text-xs text-gray-600 hover:underline hover:text-blue-400 flex justify-end">Forget Password?</a>
                  <div>
                    <Link className="btn btn-block btn-primary" to="/welcome" unstable_viewTransition>Login</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </article>
      </main>

      <footer class="flex gap-6 border-t border-t-neutral min-h-12">
        <a href="#" class="p-2 hover:border-t hover:border-t-blue-400 hover:-mt-[1px] hover:text-blue-400">How it
          works</a>
        <a href="#" class="p-2 hover:border-t hover:border-t-blue-400 hover:-mt-[1px] hover:text-blue-400">Privacy
          Policy</a>
        <a href="#" class="p-2 hover:border-t hover:border-t-blue-400 hover:-mt-[1px] hover:text-blue-400">Terms of
          Service</a>
      </footer>
    </div>
  )
}

export default LoginPage
